<template>
  <div class="flex items-center">
    <vs-avatar
      :src="banner"
      class="flex-shrink-0 mr-2"
      size="80px"
      @click="$router.push(url)"
    />
  </div>
</template>

<script>
export default {
  name: "CellRendererBanner",
  computed: {
    url() {
      if (this.params.data.logo == "partners/default.png") {
        return this.$store.state.AppActiveUser.photoURL;
      } else {
        return this.params.data.logo;
      }

      // Below line will be for actual product
      // Currently it's commented due to demo purpose - Above url is for demo purpose
      // return "/apps/user/user-view/" + this.params.data.id
    },
  },
};
</script>
